<template>
    <div>
       
        <div class="mb-3">
            <div class="btn-group campaign-btn-group d-none d-md-block" data-v-step="step-switch">
                <p-button type="primary" round outline nativeLabel :class="{ 'active' : showListGroup}" @click="changeView('groups')"> <i class="fa fa-users mr-1"></i>{{$t('entityViews.hvGroupList.filter.groupLabel')}}</p-button>
                <p-button type="primary" round outline nativeLabel :class="{ 'active' : showHvs}" @click="changeView('hvs')"> <i class="fa fa-user mr-1"></i> {{$t('entityViews.hvGroupList.filter.hvLabel')}}</p-button>
                <p-button type="primary" round outline nativeLabel :class="{ 'active' : showImport}" @click="changeView('import')"> <i class="fa fa-upload mr-1"></i> {{$t('entityViews.hvGroupList.filter.importLabel')}}</p-button>
            </div>
            <div class="row d-md-none">
                <div class="col-12">
                    <p-button type="primary" class="w-100" outline nativeLabel @click="changeView('groups')" :class="{'active' : showListGroup }"> <i class="fa fa-users"></i> {{$t('entityViews.hvGroupList.filter.groupLabel')}}</p-button>
                </div>
                <div class="col-12 col-sm-6">
                    <p-button type="primary" class="w-100" outline nativeLabel @click="changeView('hvs')" :class="{ 'active' : showHvs }"> <i class="fa fa-user"></i> {{$t('entityViews.hvGroupList.filter.hvLabel')}}</p-button>
                </div>
                <div class="col-12 col-sm-6">
                    <p-button type="primary" class="w-100" outline nativeLabel @click="changeView('import')" :class="{ 'active' : showImport }"> <i class="fa fa-upload"></i> {{$t('entityViews.hvGroupList.filter.importLabel')}}</p-button>
                </div>
            </div>
        </div>

        <div v-if="showListGroup">
            <div class="row">
                <div class="col-12">
                    <div class="card" data-v-step="step-listg" ref="groupscontent">
                        <div class="card-header">
                            <div class="row">
                                <div class="col d-flex justify-content-start">
                                    <h4 class="card-title">{{$t('entityViews.hvGroupList.groupHeader')}}</h4>
                                </div>
                                <div class="col text-right">
                                    <el-tooltip :content="$t('entityViews.hvGroupList.btnRefreshTooltip')" :open-delay="300" placement="top">
                                        <p-button type="neutral" class="mr-2" icon @click="refresh(true)">
                                            <i class="fa-solid fa-arrows-rotate"></i>
                                        </p-button>
                                    </el-tooltip>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <!-- toolbar -->
                            <div class="row toolbar">
                                <div class="col-12">
                                    <div class="">
                                        <p-button type="success" @click="createNewHvGroup" data-v-step="step-newgroup">
                                            <i class="nc-icon nc-simple-add"></i> {{$t('entityViews.hvGroupList.gToolbar.btnNewG')}}
                                        </p-button>
                                        <p-button type="primary" @click="sortEmp2Group" data-v-step="step-sortemp2group" v-if="manualControl && lazyAIStopped">
                                            <i class="fa fa-sort"></i> {{$t('entityViews.hvGroupList.gToolbar.btnSortHv2G')}}
                                        </p-button>
                                    </div>
                                </div>
                            </div>
                            <!-- header -->
                            <div class="row justify-content-between">
                                <div class="col-12 col-md-6 pt-0 pt-md-2">

                                </div>
                                <div class="col-12 col-md-6 col-xl-4 offset-xl-2 mt-2 mt-md-0">
                                    <div class="d-flex justify-content-end">
                                        <fg-input class="input-sm mr-2"
                                                  :placeholder="$t('entityViews.hvGroupList.searchPlaceholder')"
                                                  v-model="g_searchQuery"
                                                  addon-right-icon="nc-icon nc-zoom-split">
                                        </fg-input>
                                        <el-select class="select-default float-sm-left page-size"
                                                   v-model="g_pagination.perPage">
                                            <el-option class="select-default"
                                                       v-for="item in g_pagination.perPageOptions"
                                                       :key="item"
                                                       :label="item"
                                                       :value="item">
                                            </el-option>
                                        </el-select>
                                    </div>
                                </div>

                            </div>
                            <!-- table -->
                            <div class="row">
                                <div class="col-12" ref="table">
                                    <el-table :data="g_queriedData" :empty-text="$t('entityViews.hvGroupList.table.noData')"
                                              :default-sort="{ prop: 'name', order: 'ascending'}"
                                              :row-class-name="groupTableRowClassName"
                                              @sort-change="g_handleSortChange">
                                        <el-table-column prop="name" sortable="custom" :label="$t('entityViews.hvGroupList.table.colName')">
                                            <template slot-scope="props">
                                                <span v-if="props.row.isSystem">{{translateGroupName(props.row.name, props.row.isSystem)}}</span>
                                                <span v-else>{{props.row.name}}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="checkedDomains" sortable="custom" :label="$t('entityViews.hvGroupList.table.colDomains.header')" :width="150" align="center">
                                            <template slot-scope="props">
                                                <el-tooltip :content="$t('entityViews.hvGroupList.table.colDomains.dValidTooltip')" :open-delay="300" placement="left" v-if="props.row.checkedDomains">
                                                    <i class="fa fa-check" aria-hidden="true"></i>
                                                </el-tooltip>
                                                <el-tooltip :content="$t('entityViews.hvGroupList.table.colDomains.dNoValidTooltip')" :open-delay="300" placement="left" v-else>
                                                    <i class="fa fa-times" aria-hidden="true"></i>
                                                </el-tooltip>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="countHoaxVictim" sortable="custom" :label="$t('entityViews.hvGroupList.table.colHvs')" :width="150" align="center">
                                        </el-table-column>
                                        <el-table-column prop="countCampaigns" sortable="custom" :label="$t('entityViews.hvGroupList.table.colCamps')" :width="150" align="center">
                                        </el-table-column>
                                        <el-table-column :width="120" class-name="td-actions" align="center">
                                            <template slot="header" slot-scope="scope">
                                                <span data-v-step="step-groupactions">{{$t('entityViews.hvGroupList.table.colActions.header')}}</span>
                                            </template>
                                            <template slot-scope="props">
                                                <el-tooltip :content="$t('entityViews.hvGroupList.table.colActions.btnDetail')" :open-delay="300" placement="top">
                                                    <router-link class="btn btn-default btn-icon btn-sm" :to="{ name: 'eHVGroup', params:{ ghid: props.row.hid}}">
                                                        <i class="fa-solid fa-users-gear"></i>
                                                    </router-link>
                                                </el-tooltip>
                                                <el-tooltip :content="$t('entityViews.hvGroupList.table.colActions.btnDelete')" :open-delay="300" placement="top" v-show="!props.row.isSystem">
                                                    <p-button type="danger" size="sm" icon @click="deleteGroup(props.row.hid, props.row.name)">
                                                        <i class="fa-solid fa-trash-can"></i>
                                                    </p-button>
                                                </el-tooltip>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </div>
                            </div>
                            <!-- paging -->
                            <div class="row">
                                <div class="col-sm-6 pagination-info">
                                    <p class="category">{{$t('entityViews.hvGroupList.pagingInfo', [g_from + 1, g_to, g_total])}}</p>
                                </div>
                                <div class="col-sm-6">
                                    <p-pagination class="pull-right"
                                                  v-model="g_pagination.currentPage"
                                                  :per-page="g_pagination.perPage"
                                                  :total="g_pagination.total">
                                    </p-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- **************************************************************************-->

        <div ref="usersContent" v-if="showHvs">
            <div class="row">
                <div class="col-12">
                    <hv-manage-table ref="hvTable" :entityHID="entityHID" viewBy="entity" :title="$t('entityViews.hvGroupList.hvTable.header')" showHasGroupUserFilter data-v-step="step-listhvs">
                        <template v-slot:toolbar-start>
                            <p-button type="success" @click="createNewHV" data-v-step="step-newhv">
                                <i class="nc-icon nc-simple-add"></i> {{$t('entityViews.hvGroupList.hvTable.toolbar.btnNewHv')}}
                            </p-button>
                        </template>
                        <template v-slot:toolbar-end>
                            <p-button type="danger" @click="clearEntity" data-v-step="step-clearentity">
                                <i class="fa fa-times"></i> {{$t('entityViews.hvGroupList.hvTable.toolbar.btnDelAllHvs')}}
                            </p-button>
                        </template>
                    </hv-manage-table>
                </div>
            </div>
        </div>


        <hoax-victim-detail :show="modals.hvDetailModal"
                            :entityHID="entityHID"
                            :title="$t('entityViews.hvGroupList.hvDetailModal.header')"
                            :confirmBtnLabel="$t('entityViews.hvGroupList.hvDetailModal.btnConfirm')"
                            @hide="hvDetailHide"
                            @save="hoaxVictimCreate">
        </hoax-victim-detail>

        <!-- **************************************************************************-->

        <div v-if="showImport">
            <div class="row">
                <div class="col-12">
                    <div class="row" ref="hvgstatus">
                        <div class="col-md-6 col-12">
                            <stats-card type="info" data-v-step="step-empused"
                                        icon="fa fa-user">
                                <template v-slot:content>
                                    <div class="numbers">
                                        <p class="card-category">
                                            {{$t('entityViews.hvGroupList.hvsCard.header')}}
                                            <el-tooltip :open-delay="300" placement="top">
                                                <span slot="content" v-html="$t('entityViews.hvGroupList.hvsCard.tooltip')">
                                                </span>
                                                <i class="fa fa-info-circle" aria-hidden="true"></i>
                                            </el-tooltip>
                                        </p>
                                        <p class="card-title">{{hvgStatus.userUsed}}/{{hvgStatus.userLimit}}</p>
                                    </div>
                                </template>
                            </stats-card>
                        </div>
                        <div class="col-md-6 col-12">
                            <stats-card type="info" data-v-step="step-grouplimit"
                                        icon="fa fa-users">
                                <template v-slot:content>
                                    <div class="numbers">
                                        <p class="card-category">
                                            {{$t('entityViews.hvGroupList.hvgCardLimit.header')}}
                                            <el-tooltip :content="$t('entityViews.hvGroupList.hvgCardLimit.tooltip')" :open-delay="300" placement="top">
                                                <i class="fa fa-info-circle" aria-hidden="true"></i>
                                            </el-tooltip>
                                        </p>
                                        <p class="card-title">{{hvgStatus.hvGroupUsed}}/{{hvgStatus.hvGroupLimit}}</p>
                                    </div>
                                </template>
                            </stats-card>
                        </div>
                    </div>
                </div>

                <div class="col-12">
                    <card>
                        <h4 slot="header" class="card-title">
                            {{$t('entityViews.hvGroupList.uploadCard.header')}}
                        </h4>
                        <div class="text-muted">
                            <p>
                                {{$t('entityViews.hvGroupList.uploadCard.text1')}}
                                <br />
                                <div> {{$t('entityViews.hvGroupList.uploadCard.row1')}}</div>
                                <div> {{$t('entityViews.hvGroupList.uploadCard.row1')}}</div>
                                <div> {{$t('entityViews.hvGroupList.uploadCard.row1')}}</div>
                            </p>

                            <p>
                                {{$t('entityViews.hvGroupList.uploadCard.text2')}}
                            </p>

                            <div v-html="$t('entityViews.hvGroupList.uploadCard.point1')"></div>
                            <div v-html="$t('entityViews.hvGroupList.uploadCard.point2')"></div>
                            <div v-html="$t('entityViews.hvGroupList.uploadCard.point3')"></div>
                            <div v-html="$t('entityViews.hvGroupList.uploadCard.point4')"></div>
                            <div v-html="$t('entityViews.hvGroupList.uploadCard.point5')"></div>
                        </div>
                    </card>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-md-4 order-2 order-md-1">
                    <control-card>
                        <div slot="icon">
                            <div class="icon-big text-center text-success">
                                <i class="fa-solid fa-person-circle-plus"></i>
                                <div class="upload-icon-text">{{$t('entityViews.hvGroupList.uploadCard.addNewHvs.iconText')}}</div>
                            </div>
                        </div>
                        <div class="row" slot="footer">
                            <div class="col-12 text-center">
                                <upload-button :entityHID="entityHID" mode="toEntity" uploadMode="addOrUpdate"
                                               color="white" backgroundColor="#66615B" class=""
                                               :url="uploadUrl"
                                               @uploadFinished="uploadFinished" :btnLabel="$t('entityViews.hvGroupList.uploadCard.addNewHvs.btnUpload')"
                                               tooltipPlacement="top">
                                </upload-button>
                            </div>
                        </div>
                    </control-card>
                </div>

                <div class="col-12 col-md-8 order-1  order-md-1">
                    <card>
                        <h6 slot="header">{{$t('entityViews.hvGroupList.uploadCard.addNewHvs.header')}}</h6>
                        <div>
                            <div>{{$t('entityViews.hvGroupList.uploadCard.addNewHvs.text1')}}</div>
                            <div>{{$t('entityViews.hvGroupList.uploadCard.addNewHvs.text2')}}</div>
                            <div>{{$t('entityViews.hvGroupList.uploadCard.addNewHvs.text3')}}</div>
                            <div class="mt-2">{{$t('entityViews.hvGroupList.uploadCard.addNewHvs.text4')}}</div>
                        </div>
                    </card>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-md-4 order-2 order-md-1">
                    <control-card>
                        <div slot="icon">
                            <div class="d-flex flex-row justify-content-center">
                                <div class="icon-big text-center text-success">
                                    <i class="fa-solid fa-person-circle-plus"></i>
                                    <div class="upload-icon-text">{{$t('entityViews.hvGroupList.uploadCard.addRemoveHvs.iconText1')}}</div>
                                </div>
                                <div class="d-flex justify-content-center align-items-center">
                                    <span class="upload-icon-combiner">{{$t('entityViews.hvGroupList.uploadCard.addRemoveHvs.iconCombiner')}}</span>
                                </div>
                                <div class="icon-big text-center text-danger">
                                    <i class="fa-solid fa-person-circle-minus"></i>
                                    <div class="upload-icon-text">{{$t('entityViews.hvGroupList.uploadCard.addRemoveHvs.iconText2')}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row" slot="footer">
                            <div class="col-12 text-center">
                                <upload-button :entityHID="entityHID" mode="toEntity" uploadMode="noIncludedRemove"
                                               color="white" backgroundColor="#66615B"
                                               :url="uploadUrl"
                                               @uploadFinished="uploadFinished" :btnLabel="$t('entityViews.hvGroupList.uploadCard.addRemoveHvs.btnUpload')"
                                               tooltipPlacement="top">
                                </upload-button>
                            </div>
                        </div>
                    </control-card>
                </div>

                <div class="col-12 col-md-8 order-1  order-md-1">
                    <card>
                        <h6 slot="header">{{$t('entityViews.hvGroupList.uploadCard.addRemoveHvs.header')}}</h6>
                        <div>
                            <div>{{$t('entityViews.hvGroupList.uploadCard.addRemoveHvs.text1')}}</div>
                            <div>{{$t('entityViews.hvGroupList.uploadCard.addRemoveHvs.text2')}}</div>
                            <div>{{$t('entityViews.hvGroupList.uploadCard.addRemoveHvs.text3')}}</div>
                            <div>{{$t('entityViews.hvGroupList.uploadCard.addRemoveHvs.text4')}}</div>
                            <div class="mt-2">{{$t('entityViews.hvGroupList.uploadCard.addRemoveHvs.text5')}}</div>
                        </div>
                    </card>
                </div>
            </div>

        </div>


    </div>
</template>


<script>
    import { CampaignTools, HvGroupTools, MaturityLevelTools, LazyAITools } from 'src/lpLibrary.js'

    import { Input, Select, Option, Table, TableColumn } from 'element-ui'
    import { Collapse, CollapseItem, Card, Progress, StatsCard, ControlCard } from 'src/components/UIComponents'
    import swal from 'sweetalert2'
    import PPagination from 'src/components/UIComponents/Pagination.vue'
    import HvManageTable from 'src/components/UIComponents/HVManageTable.vue'
    import UploadButton from 'src/components/UIComponents/UploadButton.vue'
    import hvDetail from 'src/components/UIComponents/ModalDialog/HoaxVictimDetail'


    function g_sortingHandler(a, b, sortObject) {
        let comparison = 0;
        let sign = sortObject.order == "descending" ? -1 : 1;
        if (a[sortObject.name] > b[sortObject.name]) {
            comparison = 1 * sign;
        } else if (a[sortObject.name] < b[sortObject.name]) {
            comparison = -1 * sign;
        }
        return comparison;
    }

    export default {
        components: {
            [Input.name]: Input,
            [Option.name]: Option,
            [Select.name]: Select,
            CollapseItem,
            Collapse,
            Card,
            [Progress.name]: Progress,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn,
            PPagination,
            [HvManageTable.name]: HvManageTable,
            StatsCard,
            [UploadButton.name]: UploadButton,
            [hvDetail.name]: hvDetail,
            ControlCard,
        },
        data() {
            return {
                entityHID: 0,
                view: 'groups',
                hvgStatus: {
                    userLimit: 0,
                    userUsed: 0,
                    hvGroupLimit: 0,
                    hvGroupUsed: 0,
                },
                modals: {
                    hvDetailModal: false,
                },
                manualControl: false,
                lazyAIStopped: false,
                //-------------------------------------------------
                hvGroups: [],
                g_pagination: {
                    perPage: 25,
                    currentPage: 1,
                    perPageOptions: [10, 25, 50, 100],
                    total: 0
                },
                g_propsToSearch: ['name', 'countHoaxVictim', 'countCampaigns'],
                g_searchQuery: '',
                g_sortObject: { order: 'ascending', name: 'name' },
            }
        },
        methods: {
            initComponent: function (force) {
                this.manualControl = this.$store.getters.appState.entity.manualControl;
                this.lazyAIStopped = this.$store.getters.appState.entity.lazyAIStatus === LazyAITools.Status_Stopped;

                if (this.view === 'groups') {
                    setTimeout(() => { this.loadHvGroups(force); }, 200);
                }
                if (this.view === 'import') {
                    setTimeout(() => { this.loadHvgStatus(); }, 200);
                }
            },
            createNewHvGroup() {
                var vueThis = this;
                swal.fire({
                    title: vueThis.$t('entityViews.hvGroupList.newGroupAlert.msg'),
                    input: 'text',
                    showCancelButton: true,
                    inputValidator: (value) => {
                        if (!value) {
                            return vueThis.$t('entityViews.hvGroupList.newGroupAlert.errReq');
                        }
                        if (value.trim().length > this.$root.config.maxHvGroupNameLength) {
                            return vueThis.$t('entityViews.hvGroupList.newGroupAlert.errMax', [this.$root.config.maxHvGroupNameLength]);
                        }
                    }
                }).then(function (result) {
                    if (!result.value) return;
                    vueThis.axios.post(vueThis.$root.config.entity.hvGroup.createHvGroupUrl(vueThis.entityHID), null, { params: { name: result.value.trim() } })
                        .then(response => {
                            if (result == null || result == undefined) {
                                result = { value: "NaN" };
                            }
                            vueThis.loadHvGroups();
                            swal.fire({
                                icon: 'success',
                                html: vueThis.$t('entityViews.hvGroupList.newGroupAlert.success', [result.value]),
                                confirmButtonClass: 'btn btn-success btn-fill',
                                timer: vueThis.$root.config.alertTimer15,
                                timerProgressBar: true,
                            });
                        })
                        .catch(function (error) {
                            if (error.response.status == 400) {
                                if (result == null || result == undefined) {
                                    result = { value: "NaN" };
                                }
                                //100400, 500103
                                let respMsg = error.response.data;
                                swal.fire({
                                    title: vueThis.$t('entityViews.hvGroupList.newGroupAlert.failed'),
                                    text: vueThis.$t('apiErrorCode.' + respMsg.code + '.msg', { name: result.value }),
                                    icon: vueThis.$t('apiErrorCode.' + respMsg.code + '.icon'),
                                    showConfirmButton: true,
                                    timer: vueThis.$root.config.alertTimer15,
                                    timerProgressBar: true,
                                });
                            }
                            else {
                                vueThis.writeEx2Log(error);
                                vueThis.genericErrorAlert();
                            }
                        });
                });
            },
            loadHvGroups(force) {
                if (force == undefined) force = false;
                if (!this.entityHID) return;
                let vueThis = this;
                let loader = this.$loading.show(this.$root.config.getLoadingCfg(this.$refs.groupscontent));                
                this.axios.post(this.$root.config.entity.hvGroup.getHvGroupsUrl(this.entityHID), null, { params: { force: force } })
                    .then(response => {
                        loader.hide();
                        this.hvGroups = response.data;
                        this.hvGroups.forEach(function (item) {
                            var suiData = HvGroupTools.GetStatusUIData(item.status);
                            item.statusLabelId = suiData.labelId;
                            item.statusIcon = suiData.icon;
                            item.isActive = item.status == HvGroupTools.Status_Active;

                            item.hasActiveCampaign = item.hasScheduledCampaign || item.hasRunningCampaign || item.hasStoppedCampaign;
                            if (item.currentOrNextCampaign != null) {
                                var uiData = CampaignTools.GetStatusUIData(item.currentOrNextCampaign.status);
                                item.currentOrNextCampaign.statusLabelId = uiData.labelId;
                                item.currentOrNextCampaign.statusIcon = uiData.icon;
                                item.currentOrNextCampaign.startDate = Date.parse(item.startUtc);
                                item.currentOrNextCampaign.endDate = Date.parse(item.endUtc);
                            }

                        });
                        
                    }).catch(function (error) {
                        vueThis.writeEx2Log(error);
                        vueThis.genericErrorAlert();
                        loader.hide();
                    });
            },
            loadHvgStatus() {
                if (!this.entityHID) return;
                let vueThis = this;
                let loader = this.$loading.show(this.$root.config.getLoadingCfg(this.$refs.hvgstatus));
                this.axios.post(this.$root.config.entity.hvGroup.getHvGroupStatusUrl(this.entityHID), null)
                    .then(response => {
                        loader.hide();
                        this.hvgStatus = response.data;                        
                    }).catch(function (error) {
                        vueThis.writeEx2Log(error);
                        vueThis.genericErrorAlert();
                        loader.hide();
                    });
            },
            changeView(val) {
                //let thisVue = this;
                //this.$nextTick()
                //    .then(function () {
                //        if (thisVue.view === "groups")
                //            thisVue.loadHvGroups();
                //    });
                if (this.view != val) {
                    this.$router.push({ name: 'eHVGroupsList', query: { v: val } });
                }
                else {
                    this.view = val;
                }
            },
            translateGroupName(name, isSystem) {
                return MaturityLevelTools.translateGroupName(name, isSystem);
            },
            async deleteGroup(ghid, name) {
                let vueThis = this;
                var hvGroup = this.hvGroups.find(g => g.hid == ghid);
                if (hvGroup.hasActiveCampaign) {
                    swal.fire({
                        icon: 'error',
                        title: vueThis.$t('common.delGroupAlert.errActiveCamp.msg', [name]),
                        text: vueThis.$t('common.delGroupAlert.errActiveCamp.text'),
                        showCancelButton: false,
                    });
                    return;
                }

                if (hvGroup.countCampaigns > 0) {
                    let { value: confirm } = await swal.fire({
                        icon: 'warning',
                        title: vueThis.$t('common.delGroupAlert.warnHasCamp.msg'),
                        text: vueThis.$t('common.delGroupAlert.warnHasCamp.text'),
                        showCancelButton: false,
                    });
                }

                let cfg = this.$root.config.getSweetAlertCfg_YesNo;
                cfg.title = vueThis.$t('common.delGroupAlert.msg',);
                cfg.text = vueThis.$t('common.delGroupAlert.text', [name]);
                swal.fire(cfg).then((result) => {
                    if (result.value) {
                        swal.fire(this.$root.config.getSweetAlertCfg_Waiting);
                        this.axios.post(this.$root.config.entity.hvGroup.hvGroupDeleteUrl(this.entityHID), null, { params: { groupHID: ghid } })
                            .then(response => {
                                swal.close();
                                vueThis.loadHvGroups();
                                swal.fire({
                                    icon: 'success',
                                    title: vueThis.$t('common.delGroupAlert.success', [name]),
                                    showConfirmButton: true,
                                    timer: vueThis.$root.config.alertTimer15,
                                    timerProgressBar: true,
                                });
                            }).catch(function (error) {
                                swal.close();
                                if (error.response.status == 400) {
                                    swal.fire({
                                        icon: 'error',
                                        title: vueThis.$t('common.delGroupAlert.failed'),
                                        showConfirmButton: true,
                                        timer: vueThis.$root.config.alertTimer15,
                                        timerProgressBar: true,
                                    });
                                }
                                else {
                                    vueThis.writeEx2Log(error);
                                    vueThis.genericErrorAlert();
                                }
                            });
                    }
                });
            },
            uploadFinished: function (response, errCode, errMsg, errObj) {
                let vueThis = this;
                if (response) {
                    //this.$refs.hvTable.refresh();
                    this.loadHvgStatus();
                    swal.fire({
                        icon: 'success',
                        title: vueThis.$t('common.uploadHvsAlert.success'),
                        showConfirmButton: true,
                        timer: this.$root.config.alertTimer15,
                        timerProgressBar: true,
                    });
                }
                else {
                    if (errObj == null || errObj == undefined) {
                        errObj = { domaiName: "NaN", email: "NaN", phoneNumber: "NaN" };
                    }
                    //500001, 500002, 500003, 500101, 500103, 500202, 500102, 500104
                    swal.fire({
                        title: vueThis.$t('common.uploadHvsAlert.failed'),
                        text: vueThis.$t('apiErrorCode.' + errCode + '.msg', { name: errObj.domainName, email: errObj.email, phoneNumber: errObj.phoneNumber }),
                        icon: vueThis.$t('apiErrorCode.' + errCode + '.icon'),
                        showConfirmButton: true,
                        timer: vueThis.$root.config.alertTimer15,
                        timerProgressBar: true,
                    });
                }
            },
            refresh(force) {
                this.loadHvGroups(force);
            },
            sortEmp2Group() {
                if (!this.entityHID) return;
                let vueThis = this;
                let loader = this.$loading.show(this.$root.config.getLoadingCfg(this.$refs.groupscontent));
                this.axios.post(this.$root.config.entity.hvGroup.sortEmp2GroupsUrl(this.entityHID), null)
                    .then(response => {
                        loader.hide();
                        vueThis.refresh();
                        swal.fire({
                            icon: 'success',
                            title: vueThis.$t('entityViews.hvGroupList.sortHv2GSuccess'),
                            confirmButtonClass: 'btn btn-success btn-fill',
                            timer: vueThis.$root.config.alertTimer15,
                            timerProgressBar: true,
                        });
                    }).catch(function (error) {
                        vueThis.writeEx2Log(error);
                        vueThis.genericErrorAlert();
                        loader.hide();
                    });
            },
            clearEntity() {
                let vueThis = this;
                let cfg = this.$root.config.getSweetAlertCfg_YesNo;
                cfg.title = vueThis.$t('entityViews.hvGroupList.clearGAlert.msg');
                cfg.text = vueThis.$t('entityViews.hvGroupList.clearGAlert.text');
                swal.fire(cfg).then((result) => {
                    if (result.value) {
                        swal.fire(this.$root.config.getSweetAlertCfg_Waiting);
                        this.axios.post(this.$root.config.entity.hv.allHoaxVictimsDeleteUrl(this.entityHID), null)
                            .then(response => {
                                vueThis.$refs.hvTable.refresh();
                                swal.fire({
                                    icon: 'success',
                                    title: vueThis.$t('entityViews.hvGroupList.clearGAlert.success'),
                                    showConfirmButton: true,
                                    timer: vueThis.$root.config.alertTimer15,
                                    timerProgressBar: true,
                                });
                            }).catch(function (error) {
                                if (error.response.status == 400) {
                                    swal.fire({
                                        icon: 'error',
                                        title: vueThis.$t('entityViews.hvGroupList.clearGAlert.failed'),
                                        showConfirmButton: true,
                                        timer: vueThis.$root.config.alertTimer15,
                                        timerProgressBar: true,
                                    });
                                }
                                else {
                                    vueThis.writeEx2Log(error);
                                    vueThis.genericErrorAlert();
                                }
                            });
                    }
                });
            },
            uploadModeChange(event) {
                console.log(event);
            },
            //-----------------
            g_handleSortChange(e) {
                this.g_sortObject = { order: e.order, name: e.prop };
            },
            groupTableRowClassName({ row, rowIndex, }) {
                if (row.isSystem == true) {
                    return "system-group " + MaturityLevelTools.getGroupCssClass(row.name);
                }
                return '';
            },
            //-----------------
            createNewHV() {
                this.modals.hvDetailModal = true;
            },
            hoaxVictimCreate(hvData) {
                //console.log(hvData);
                let vueThis = this;
                this.axios.post(this.$root.config.entity.hv.hoaxVictimCreateUrl(this.entityHID), hvData)
                    .then(response => {
                        vueThis.modals.hvDetailModal = false;
                        vueThis.refresh();
                        vueThis.$refs.hvTable.refresh();
                        swal.fire({
                            icon: 'success',
                            title: vueThis.$t('entityViews.hvGroupList.createHvAlert.success'),
                            showConfirmButton: true,
                            timer: vueThis.$root.config.alertTimer15,
                            timerProgressBar: true,
                        });
                    }).catch(function (error) {
                        if (error.response.status == 400) {
                            // 500001, 500002, 500003, 500101, 500103, 500104, 500105, 500202, 
                            let respMsg = error.response.data;
                            swal.fire({
                                title: vueThis.$t('entityViews.hvGroupList.createHvAlert.failed'),
                                text: vueThis.$t('apiErrorCode.' + respMsg.code + '.msg'),
                                icon: vueThis.$t('apiErrorCode.' + respMsg.code + '.icon'),
                                showConfirmButton: true,
                                timer: vueThis.$root.config.alertTimer15,
                                timerProgressBar: true,
                            });
                        }
                        else {
                            vueThis.writeEx2Log(error);
                            vueThis.genericErrorAlert();
                        }
                    });
            },
            hvDetailHide() {
                this.modals.hvDetailModal = false;
            },
        },
        computed: {
            showListGroup() {
                return this.view == 'groups';
            },
            showHvs() {
                return this.view == 'hvs';
            },
            showImport() {
                return this.view == 'import';
            },
            uploadUrl() {
                return this.$root.config.entity.uploadHvsUrl(this.entityHID);
            },
            //--------------------------
            g_queriedData() {
                let cThis = this;
                let systemGroups = this.hvGroups.filter(c => c.isSystem == true).sort(function (a, b) { return g_sortingHandler(a, b, { order: 'ascending', name: 'name' }); });
                //systemGroups[0].color = 'red';
                let customGroups = this.hvGroups.filter(c => c.isSystem == false);

                if (!this.g_searchQuery) {
                    this.g_pagination.total = this.hvGroups.length;
                    return systemGroups.concat(customGroups.sort(function (a, b) { return g_sortingHandler(a, b, cThis.g_sortObject); })).slice(this.g_from, this.g_to);
                }
                let result = customGroups
                    .filter((row) => {
                        let isIncluded = false;
                        for (let key of this.g_propsToSearch) {
                            let rowValue = row[key].toString();
                            if (rowValue.includes && rowValue.toLowerCase().includes(this.g_searchQuery.toLowerCase())) {
                                isIncluded = true;
                            }
                        }
                        return isIncluded;
                    })
                this.g_pagination.total = systemGroups.length + result.length;
                return systemGroups.concat(result.sort(function (a, b) { return g_sortingHandler(a, b, cThis.g_sortObject); })).slice(this.g_from, this.g_to);
            },
            g_to() {
                let highBound = this.g_from + this.g_pagination.perPage;
                if (this.g_total < highBound) {
                    highBound = this.g_total;
                }
                return highBound;
            },
            g_from() {
                if (this.g_pagination.total == 0) return -1;
                return this.g_pagination.perPage * (this.g_pagination.currentPage - 1);
            },
            g_total() {
                return this.g_pagination.total;
            },

            tourSteps() {
                let viewTour = [];
                if (this.view === 'groups') {
                    viewTour = this.hvgTourSteps;
                }
                if (this.view === 'hvs') {
                    viewTour = this.hvsTourSteps;
                }
                if (this.view === 'import') {
                    viewTour = this.importTourSteps;
                }
                return this.generalTourSteps.concat(viewTour);
            },
        },
        mounted: function () {
            this.initComponent();
        },
        watch: {
            $route(to, from) {
                if (to !== from) {
                    this.entityHID = this.$route.params.ehid;
                    let v = this.$route.query.v;
                    if (v == undefined) v = 'groups';
                    this.view = v;
                    this.initComponent();                    
                }
            }
        },
        created() {
            this.entityHID = this.$route.params.ehid;
            let v = this.$route.query.v;
            if (v == undefined) v = 'groups';
            this.view = v;
        },
    }

</script>

<style scoped>
</style>